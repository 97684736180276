import useSWR from 'swr'
import { useCallback, useState, useMemo } from 'react'
import { captureException } from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { GrowthBook } from '@growthbook/growthbook'

import { getCookie } from '../../utils/cookies'
import { MOCK_SKUS, VertexAIRecommendationModelType, VertexAIUserEventType } from '../../utils/constants'
import { isProduction } from '../../utils/environment'

// Define the API response interface
export interface RecommendationsResponse {
  recommendations: Array<{
    id: string
    metadata: Record<string, string>
  }>
}

// Define the error response interface
export interface ErrorResponse {
  error: string
}

// Define the product detail interface
export interface ProductDetail {
  product: { id: string }
  quantity?: { value: number }
}

// Define the purchase transaction interface
export interface PurchaseTransaction {
  currencyCode: string
  revenue: number
}

// Define the hook parameters
export interface ProductSuggestionsParams {
  filter?: string
  categoryFilter?: string
  supplierFilter?: string
  associatedItems?: Array<string | [string, number]>
  pageCategories?: string | string[]
  pageSize?: number
  cartId?: string
  purchaseTransaction?: {
    currencyCode: string
    revenue: number
  }
  defaultEventType?: VertexAIUserEventType
  modelType: VertexAIRecommendationModelType
}

/**
 * Gets a persistent client ID, first trying the GA cookie,
 * then falling back to localStorage for consistency across sessions
 * even when cookies are unavailable.
 *
 * The GA client ID format after extraction is: XXXXXXXXXX.XXXXXXXXXX
 * We'll match this format for our fallback IDs for consistency with Vertex AI
 */
const getClientId = (): string => {
  // Try to get GA client ID first
  const gaClientId = getCookie('_ga')?.[1]?.substring(6)
  if (gaClientId) {
    return gaClientId
  }

  // If no GA cookie, try to get from localStorage
  try {
    let persistentId = localStorage.getItem('vertex_visitor_id')

    // If no ID in localStorage, generate a new one in GA format
    if (!persistentId) {
      // Generate two random numbers to match GA format XXXXXXXXXX.XXXXXXXXXX
      const timestamp = Math.floor(Date.now() / 1000)
      const random = Math.floor(Math.random() * 1000000000)

      // Format as GA client ID format: XXXXXXXXXX.XXXXXXXXXX
      persistentId = `${timestamp}.${random}`
      localStorage.setItem('vertex_visitor_id', persistentId)
    }

    return persistentId
  } catch (e) {
    // If localStorage fails, generate a temporary ID in GA format
    const timestamp = Math.floor(Date.now() / 1000)
    const random = Math.floor(Math.random() * 1000000000)
    return `${timestamp}.${random}`
  }
}

// Create a fetcher function for the API
const fetcher = async (
  url: [string, any],
): Promise<RecommendationsResponse> => {
  // Extract the actual URL and params from the array
  const [apiUrl, params] = url

  // Use mock data for non-production environments
  if (!isProduction) {
    let useMockData = false

    // Create a new GrowthBook instance to check the feature flag
    if (typeof window !== 'undefined') {
      try {
        const growthbook = new GrowthBook({
          apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_HOST || '',
          clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY || '',
          enableDevMode: !isProduction,
        })

        // Wait for the features to load
        await growthbook.init()

        // Check if the feature flag is enabled
        useMockData = growthbook.isOn('use-mock-ai-recommendation-data')
      } catch (error) {
        console.error('Error loading GrowthBook features:', error)
        // Default to using mock data in non-production if there's an error
        useMockData = true
      }
    }

    // Return mock data for non-production environments when feature flag is enabled
    if (useMockData) {
      // Return all SKUs as recommendations
      const mockRecommendations = MOCK_SKUS.map((sku) => ({
        id: sku,
        metadata: {},
      }))

      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 500))

      return {
        recommendations: mockRecommendations,
      }
    }
  }

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = errorData.error || 'An error occurred while fetching recommendations'
      const fetchError = new Error(errorMessage)

      // Capture the error with Sentry
      captureException(fetchError, {
        level: 'error',
        tags: { component: 'useRecommendationsAPI', function: 'fetcher' },
        extra: {
          status: response.status,
          statusText: response.statusText,
          params,
          location: params.location,
        },
      })

      throw fetchError
    }

    return await response.json()
  } catch (error) {
    // Capture any unexpected errors
    captureException(error, {
      level: 'error',
      tags: { component: 'useRecommendationsAPI', function: 'fetcher' },
      extra: { params },
    })
    throw error
  }
}

/**
 * Hook for fetching recommendations from the product-recommendations API
 *
 * @param params Parameters for the hook
 * @returns Result containing recommendations data and functions to trigger events
 */
const useRecommendationsAPI = (
  params: ProductSuggestionsParams,
) => {
  const {
    filter,
    categoryFilter,
    supplierFilter,
    associatedItems = [],
    pageSize = 100,
    cartId,
    purchaseTransaction,
    defaultEventType = 'home-page-view',
    modelType,
  } = params

  const [hasTriggeredEvent, setHasTriggeredEvent] = useState(false)

  const { asPath } = useRouter()

  const clientId = getClientId()

  const requestParams = {
    clientId,
    modelType,
    eventType: defaultEventType,
    filter,
    categoryFilter,
    supplierFilter,
    associatedItems,
    pageSize,
    cartId,
    purchaseTransaction,
    location: asPath,
  }

  // Create a stable key that won't change between renders
  const stableKey = useMemo(() => {
    // If no event has been triggered yet, return null to prevent fetching
    if (!hasTriggeredEvent) {
      return null
    }

    return [
      '/api/product-recommendations',
      {
        modelType,
        associatedItems: Array.isArray(associatedItems) ? associatedItems.join(',') : '',
        supplierFilter,
        categoryFilter,
      },
    ]
  }, [hasTriggeredEvent, modelType, associatedItems, supplierFilter, categoryFilter])

  const swrConfig = {
    revalidateOnMount: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  }

  const { data, error, mutate } = useSWR<RecommendationsResponse>(
    stableKey,
    (url) => fetcher(url),
    swrConfig,
  )

  // Function to trigger a specific event
  const triggerEvent = useCallback(async (
    eventType: VertexAIUserEventType,
    triggerRequestParams?: ProductSuggestionsParams,
  ) => {
    // Update state to indicate an event has been triggered
    setHasTriggeredEvent(true)

    // Create the event-specific params
    const eventParams = {
      ...requestParams,
      ...triggerRequestParams, // Override initial request params with trigger request params
      eventType,
    }

    if (!clientId) {
      console.error('Missing required parameter: clientId')
      captureException(new Error('Missing required parameter: clientId'), {
        level: 'warning',
        tags: { component: 'useRecommendationsAPI', eventType, location: asPath },
        extra: { eventParams },
      })
      return
    }

    // Validate event-specific required parameters and handle errors gracefully
    if (eventType === 'category-page-view' && !categoryFilter) {
      const categoryError = new Error('Missing required parameter: pageCategories for category-page-view event')
      console.error(categoryError.message)
      captureException(categoryError, {
        level: 'error',
        tags: { component: 'useRecommendationsAPI', eventType, location: asPath },
        extra: { eventParams },
      })
      return // Return instead of throwing to prevent client crash
    }

    if (eventType === 'purchase-complete' && !purchaseTransaction) {
      const purchaseError = new Error('Missing required parameter: purchaseTransaction for purchase-complete event')
      console.error(purchaseError.message)
      captureException(purchaseError, {
        level: 'error',
        tags: { component: 'useRecommendationsAPI', eventType },
        extra: { eventParams },
      })
      return // Return instead of throwing to prevent client crash
    }

    try {
      // Fetch new data with the event
      const newData = await fetcher([
        '/api/product-recommendations',
        eventParams,
      ])

      // Update SWR cache with new data - use the proper options format for SWR mutate
      await mutate(newData, { revalidate: false })
    } catch (fetchError) {
      captureException(fetchError, {
        level: 'error',
        tags: { component: 'useRecommendationsAPI', eventType, location: asPath },
        extra: { eventParams },
      })
    }
  }, [requestParams, mutate, clientId, categoryFilter, cartId, purchaseTransaction])

  return {
    data,
    isLoading: !error && !data && hasTriggeredEvent,
    error,
    triggerEvent,
    mutate,
  }
}

export default useRecommendationsAPI
